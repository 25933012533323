<script>

export default {
  name: "descriptionsComplex",
  props: {
    descriptions: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    delete_description (id) {
      const el = this.descriptionsList.find(el => el.arr_id === id)
      if (el) {
        this.descriptions[el.arr_id].status = 'deleted'
      }
      else console.log('err')
    },
    save_description (id) {
      const el = this.descriptionsList.find(el => el.arr_id === id)
      if (el) {
        if (!el.description)alert(this.$i18n.t('ОписаниеНеЗаполнено'))
        else {
          this.descriptions[el.arr_id].description = el.description
          this.descriptions[el.arr_id].status = 'saved'
        }
      }

    },
    change_description (id) {
      const el = this.descriptionsList.find(el => el.arr_id === id)
      if (el) this.descriptions[el.arr_id].status = 'change'
    },
    addition_description () {
      this.descriptions.push({description: null, id: null, status: 'change'})
    }
  },
  computed: {
    descriptionsList () {
        return this.descriptions.map((el,index) => {
          return {...el, arr_id: index}
        }).filter(el => el.status !== 'deleted')
    },
  }
}
</script>

<template>
  <div class="tagsComponent">
    <p class="text-center m-0" v-if="!descriptionsList.length">{{$t('НетНиОдногоДополнительногоОписаниеНажмитеНаПлюсЧтобыДобавить')}}</p>
    <ul class="tags" v-else>
      <li class="tag" v-for="(description, id) in descriptionsList" :key="description.arr_id">
        <p @click="change_description(description.arr_id)" v-if="description.status === 'saved'" class="m-0 tag-saved">{{description.description}}</p>
        <div v-else-if="description.status === 'change'">
          <div class="tag-info">
            <p>{{$t('Описание')}} №{{id+1}}</p>
            <div>
              <div class="save-tag" @click="save_description(description.arr_id)">✔️</div>
              <div class="delete-tag" @click="delete_description(description.arr_id)">❌</div>
            </div>
          </div>
          <div class="fields">
            <textarea type="text" :placeholder="$t('Описание')" class="form-control" id="description" v-model="description.description"></textarea>
          </div>
        </div>
      </li>
    </ul>

    <div v-if="descriptionsList.length < 6" class="addition-tag">
      <div  @click="addition_description">
        <svg width="30" height="30" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.09381 7.90622H4.06256V7.09375H7.09381V4.0625H7.90628V7.09375H10.9375V7.90622H7.90628V10.9375H7.09381V7.90622Z" fill="var(--colorIconAccent)"/>
        </svg>
      </div>
    </div>


  </div>
</template>

<style scoped lang="scss">
.tagsComponent {
  @mixin flex {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .tags {
    display: flex;
    flex-direction: column;
    padding: 10px 2rem;
    gap: 14px;
    background-color: var(--colorBackgroundTertiaryHover);
    border-radius: 8px;
    .tag {
      list-style-type: none;
      > p {
        position: relative;
        padding: 5px 10px 5px 30px;
        &::before {
          left: 10px;
          position: absolute;
          content: "●";
          background-color: var(--colorBackgroundTertiaryHover);
        }
      }
      //font-size: 10px;
      p {
        margin: 0;
      }
      .tag-info {
        display: flex;
        justify-content: space-between;
        > div {
          display: flex;
          gap: 5px;
        }
        .delete-tag, .save-tag {
          cursor: pointer;
        }
      }
      .fields {
        width: 100%;
        @include flex;
      }
      .tag-saved {
        cursor: pointer;
        border-radius: 8px;
        min-width: 150px;
        &:hover {
          background-color: var(--colorBackgroundSecondaryHover);
        }
      }
    }
  }

  .addition-tag {
    margin-top: 8px;
    width: 100%;
    display: flex;
    justify-content: end;
    > div {
      background-color: var(--colorBackgroundTertiaryHover);
      width: fit-content;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        background-color: var(--colorBackgroundSecondaryHover);
      }
    }
  }
}
</style>