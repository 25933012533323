<template>
  <div class="card-body">
    <modal-container
        v-if="visibleModal"
        :title="$t('Скидка')"
        :confirmText="$t('сохранить')"
        @confirm-option="saveDataDisc"
        @close-modal="closeModalEditor"
    >
      <div class="editor-modal-blocks-project">
        <div class="form-group" v-if="litersSelect.length">
          <label for="developments">{{ $t('Литер') }}</label>
          <dropdown-component :elements="litersSelect" :open="'bottom'" :none-hide="true" :multiple="true" v-model="changeData.developments" :value="changeData.developments"></dropdown-component>
        </div>
        <div class="form-group">
          <label for="typeObject">{{ $t('Помещение') }}</label>

          <dropdown-component :elements="['Квартира', 'Апартаменты', 'Коммерция', 'Загородные дома', 'Парковки', 'Кладовки', 'Подсобное помещение', 'Офис']" :none-hide="true" :open="'bottom'" :multiple="false" v-model="changeData.typeObject" :value="changeData.typeObject"></dropdown-component>
        </div>
        <div class="form-group">
          <label for="type">{{ $t('тип') }}</label>
          <dropdown-component :elements="['Скидка', 'Наценка']" :none-hide="true" :open="'bottom'" :multiple="false" v-model="changeData.type" :value="changeData.type"></dropdown-component>
        </div>
        <div class="form-group">
          <label for="floors">{{ $t('Этажи') }}</label>
          <slot v-if="floors.length">
            <dropdown-component :elements="floors" :none-hide="true" :open="'bottom'" :multiple="true" v-model="changeData.floors" :value="changeData.floors"></dropdown-component>
          </slot>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="square_min">{{ $t('ПлощадьОтМ2') }}</label>
              <input type="number" class="form-control" id="square_min" v-model="changeData.square_min">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="square_max">{{ $t('ПлощадьДоМ2') }}</label>
              <input type="number" class="form-control" id="square_max" v-model="changeData.square_max">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="price_min">{{$t('СтоимостьОбъектаОт')}}</label>
              <input type="number" class="form-control" id="price_min" v-model="changeData.price_min">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="price_max">{{$t('СтоимостьОбъектаДо')}}</label>
              <input type="number" class="form-control" id="price_max" v-model="changeData.price_max">
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="rooms">{{ $t('Комнат') }}</label>
          <template v-if="rooms.length">
            <dropdown-component :elements="rooms" :none-hide="true" :open="'bottom'" :multiple="true" v-model="changeData.rooms" :value="changeData.rooms"></dropdown-component>
          </template>
        </div>
        <div class="form-group" v-if="planningPrinciples.length">
          <label>{{$t('Планировка')}}</label>
          <dropdown-component :elements="planningPrinciples" :none-hide="true" :open="'bottom'" :multiple="true" v-model="changeData.planning_principle" :value="changeData.planning_principle"></dropdown-component>
        </div>
        <div class="form-group">
          <label for="sorts">{{ $t('ось') }}</label>
          <template v-if="sorts.length">
            <dropdown-component :elements="sorts" :none-hide="true" :open="'bottom'" :multiple="true" v-model="changeData.sorts" :value="changeData.sorts"></dropdown-component>
          </template>
        </div>
        <div class="form-group">
          <label for="sections">{{ $t('Секция') }}</label>
          <template v-if="sections.length">
            <dropdown-component :elements="sections" :none-hide="true" :open="'bottom'" :multiple="true" v-model="changeData.sections" :value="changeData.sections"></dropdown-component>
          </template>
        </div>

        <div class="form-group">
          <label for="banks">{{ $t('БанковскийФильтр') }}</label>
          <dropdown-component :elements="programsValue" :none-hide="true" :open="'bottom'" :multiple="true" v-model="changeData.banks" :value="changeData.banks"></dropdown-component>
        </div>

        <div class="form-group">
          <label for="bpv">{{ $t('бпв') }}</label>
          <dropdown-component :elements="[{id: 0, text:'Не применяется'}, {id: 1, text:'Применяется'}, {id: -1, text:'Без разницы'}]" :none-hide="true" :open="'bottom'" :multiple="false" v-model="changeData.bpv" :value="changeData.bpv"></dropdown-component>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="sum">{{ $t('СуммаЗаМ2') }}</label>
              <input type="number" class="form-control" id="sum" disabled readonly v-if="changeData.percent || changeData.kv2 || changeData.kv1" v-model="changeData.sum">
              <input type="number" class="form-control" id="sum" v-else v-model="changeData.sum">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="sum">{{ $t('ПроцентЗаМ2') }}</label>
              <input type="number" class="form-control" id="percent" disabled readonly v-if="changeData.kv2 || changeData.sum || changeData.kv1" v-model="changeData.percent">
              <input type="number" class="form-control" id="percent" v-else v-model="changeData.percent">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="kv1">{{ $t('КВ') }}1 {{$t('за')}} {{ $t('м²') }}</label>
              <input type="number" class="form-control" id="kv1" disabled readonly v-if="changeData.percent || changeData.sum" v-model="changeData.kv1">
              <input type="number" class="form-control" id="kv1" v-else v-model="changeData.kv1">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="kv2">{{ $t('КВ') }}2 {{$t('за')}} {{ $t('м²') }}</label>
              <input type="number" class="form-control" id="kv2" disabled readonly v-if="changeData.percent || changeData.sum" v-model="changeData.kv2">
              <input type="number" class="form-control" id="kv2" v-else v-model="changeData.kv2">
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="kv3">{{$t('Наценка')}} {{$t('за')}} {{ $t('м²') }}</label>
          <input type="number" class="form-control" id="kv3" v-model="changeData.kv3">
        </div>
      </div>
    </modal-container>
    <div class="container p-30" id="wrapper-row-admin">
      <div class="row">
        <div class="col-md-12 main-datatable">
          <button class="btn btn-sm btn-success d-inline-block mb-2 col-12" @click="createDiscount()">{{ $t('Добавить') }}</button>
          <div class="tabs-shop">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a :class="'nav-link ' + (discountsType === 'actual' ? 'active':'')" @click="changeDiscountTab('actual')">{{ $t('Актуальные') }}</a>
              </li>
              <li class="nav-item">
                <a :class="'nav-link ' + (discountsType === 'archive' ? 'active':'')" @click="changeDiscountTab('archive')">{{ $t('Архив') }}</a>
              </li>
            </ul>
          </div>
          <div class="card_body">
            <div class="overflow-x">
              <table id="filtertable5" class="table cust-datatable dataTable no-footer">
                <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t('Литер') }}</th>
                  <th>{{ $t('Помещение') }}</th>
                  <th>{{ $t('тип') }}</th>
                  <th>{{ $t('Этажи') }}</th>
                  <th>{{ $t('Площадь') }}</th>
                  <th>{{ $t('Комнат') }}</th>
                  <th>{{ $t('ось') }}</th>
                  <th>{{ $t('Секция') }}</th>
                  <th>{{ $t('скидканаценка') }}</th>
                  <th>{{ $t('бпв') }}</th>
                  <th>{{ $t('Программы') }}</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>

                <tr v-for="discount in discounts" :key="discount.id">
                  <td>{{discount.id}}</td>
                  <td><slot v-if="discount.developments">{{getComplex(discount.developments)}}</slot></td>
                  <td>{{discount.typeObject}}</td>
                  <td>{{discount.type}}</td>
                  <td><slot v-if="discount.floors">{{discount.floors.join(', ')}}</slot></td>
                  <td><slot v-if="discount.square_min">{{discount.square_min}}</slot><slot v-if="discount.square_max"> - {{discount.square_max}}</slot></td>
                  <td><slot v-if="discount.rooms">{{discount.rooms.join(', ')}}</slot></td>
                  <td><slot v-if="discount.sorts">{{discount.sorts.join(', ')}}</slot></td>
                  <td><slot v-if="discount.sections">{{discount.sections.join(', ')}}</slot></td>
                  <td>
                    <slot v-if="discount.sum">{{priceFormat(discount.sum)}}/{{ $t('м²') }}</slot>
                    <slot v-else-if="discount.percent">{{discount.percent}}%/{{ $t('м²') }}</slot>
                    <slot v-if="discount.kv1">{{ $t('КВ') }}1 {{discount.kv1}}%/{{ $t('м²') }}<br></slot>
                    <slot v-if="discount.kv2">{{ $t('КВ') }}2 {{discount.kv2}}%/{{ $t('м²') }}</slot>
                    <slot v-if="discount.kv3">{{$t('Наценка')}} {{discount.kv3}}%/{{ $t('м²') }}</slot>
                  </td>
                  <td><slot v-if="discount.bpv === 0">{{$t('НеПрименяется')}}</slot><slot v-if="discount.bpv === -1">{{$t('Неважно')}}</slot><slot v-if="discount.bpv === 1">{{$t('Применяется')}}</slot></td>
                  <td><slot v-if="Object.keys(banks).length && discount.banks">{{getPrograms(discount.banks)}}</slot></td>
                  <td v-if="discountsType === 'actual'">
                    <div class="btn-admin-nowrap-column">
                      <button @click="copyDiscount(discount.id)" class="btn btn-sm btn-info">{{ $t('скопировать') }}</button>
                      <button  @click="showModal(discount)" class="btn btn-sm btn-warning">{{ $t('ВОкне') }}</button>
                      <router-link tag="a" :to="'/discount/'+discount.id" class="btn btn-sm btn-warning">{{$t('редактировать')}}</router-link>
                      <button class="btn btn-sm btn-danger m-l-10" @click="deleteDiscount(discount.id)">{{ $t('отключить') }}</button>
                    </div>
                  </td>
                  <td v-else> <button @click="returnDiscount(discount.id)" class="btn btn-sm btn-info">{{ $t('Восстановить') }}</button></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'datatables.net-bs5'
import 'datatables.net-fixedheader-bs5'
import 'datatables.net-responsive-bs5'
import 'datatables.net-select-bs5'
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css'
import $ from 'jquery'
import {mapGetters, mapMutations} from "vuex";
import priceFormat from "@/mixins/priceFormat";
import axios from "axios";
import dropdownComponent from "@/components/dropdown.vue";
import ModalContainer from "@/components/redesign/modalContainer.vue";

export default {
  name: "DiscountsEditorProject",
  components: {ModalContainer, dropdownComponent},
  mixins: [priceFormat],
  data(){
    return{
      complex:{},
      discounts: [],
      discountsType: 'actual',
      programsValue: [],
      banks: {},
      table: null,
      changeData: {},
      floors:[],
      rooms:[],
      planningPrinciples:[],
      sorts:[],
      sections:[],
      visibleModal:false
    }
  },
  methods:{
    loadDiscounts() {

      axios.get('/api/get-calculator-programs').then(response => {
        var banks = response.data
        var temp = {}
        var temp2 = []
        banks.forEach((bank) => {
          temp[bank.id] = '['+bank.id+'] '+bank.name
          temp2.push({
            id: bank.id,
            text: '['+bank.id+'] '+bank.name
          })
        })
        this.banks = temp
        this.programsValue = temp2
      })

      if(this.table !== null) this.table.destroy()
      axios.get('/api/get-discounts', {params: {
          id: this.$route.params.id,
          type: this.discountsType
        }}).then(response => {
        this.discounts = response.data
        setTimeout(() => {
          $.fn.dataTable.ext.errMode = 'none';
          this.table = $('#filtertable5').DataTable({
            stateSave: true,
            destroy: true,
            paging: true,
            pageLength: 25,
            language: {
              'decimal': '',
              'emptyTable': `${this.$i18n.t('ДанныхНет')}`,
              'info': `${this.$i18n.t('ПоказаноС')} _START_ ${this.$i18n.t('по')} _END_ ${this.$i18n.t('из')} _TOTAL_ ${this.$i18n.t('строк')}`,
              'infoEmpty': '',
              'infoFiltered': `(${this.$i18n.t('найденоИзИзначальных')} _MAX_ строк)`,
              'infoPostFix': '',
              'thousands': ',',
              'lengthMenu': `${this.$i18n.t('ПоказатьПо')} _MENU_`,
              'loadingRecords': `${this.$i18n.t('Загрузка')}...`,
              'processing': `${this.$i18n.t('Поиск')}...`,
              'search': `${this.$i18n.t('Поиск')}:`,
              'zeroRecords': `${this.$i18n.t('СовпаденийНеНайдено')}`,
              'paginate': {
                'first': `${this.$i18n.t('Первая')}`,
                'last': `${this.$i18n.t('Последняя')}`,
                'next': `${this.$i18n.t('Вперед')}`,
                'previous': `${this.$i18n.t('Назад')}`
              },
              'aria': {
                'sortAscending': `: ${this.$i18n.t('сортировкаПоВозрастанию')}`,
                'sortDescending': `: ${this.$i18n.t('сортировкаПоУбыванию')}`
              }
            },
            order: [[ 0, "desc" ]],
            'columns' : [
              {'visible' : false },
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
            ]
          })
        }, 100)
      })
    },
    changeDiscountTab(type) {
      this.discountsType = type;
      this.loadDiscounts();
    },
    createDiscount() {
      axios.post('/api/create-discount?id='+this.$route.params.id).then((res)=>{
        window.open('/discount/' + res.data.id, "_self")
      })
    },
    copyDiscount(id) {
      axios.post('/api/copy-discount?id='+id).then(()=>{
        this.loadDiscounts()
      })
    },
    deleteDiscount(id) {
      axios.post('/api/delete-discount?id='+id).then(()=>{
        this.loadDiscounts()
      })
    },
    returnDiscount(id) {
      axios.post('/api/return-discount?id='+id).then(()=>{
        this.loadDiscounts();
      })
    },
    getComplex (developments) {
      var array =[]
      developments.forEach(val => {
        if(val && this.liters[val]) {
          array.push(this.liters[val])
        }
      })
      return array.join(', ')
    },
    getPrograms (banks) {
      var array =[]
      banks.forEach(val => {
        if(val && this.banks[val]) {
          array.push(this.banks[val])
        }
      })
      return array.join(', ')
    },
    saveDataDisc () {
      var object = JSON.parse(JSON.stringify(this.changeData))
      axios.post('/api/post-discount?id='+this.changeData.id, object).then(()=>{
        this.closeModalEditor()
      })
    },
    closeModalEditor(){
      this.visibleModal=false
      this.changeData = {}
    },
    showModal (data) {
      if(data.id){
        this.changeData = data
        this.visibleModal = true
      }
    },
  },
  computed:{
    ...mapGetters({
      storeComplex: 'editorProjectData/getComplex',
      liters: 'editorProjectData/getLiters',
      litersSelect: 'editorProjectData/getLitersSelect',
    }),
  },
  watch:{
    storeComplex(newValue){
      this.complex=JSON.parse(JSON.stringify(newValue))
    }
  },
  mounted() {
    this.complex=JSON.parse(JSON.stringify(this.storeComplex))
    this.floors = Object.values(this.complex.floors)
    this.rooms = Object.values(this.complex.rooms)
    this.planningPrinciples = Object.values(this.complex.planningPrinciples)
    this.sorts = Object.values(this.complex.sorts)
    this.sections = Object.values(this.complex.sections)
    this.loadDiscounts()

  }
}
</script>

<style scoped lang="scss">

</style>