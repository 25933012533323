<template>
  <div :class="[{center}, 'loader']">
    <div :class="[size, 'spinner-border']" role="status">
      <span class="sr-only">{{ $t('загрузка') }}</span>
    </div>
    <p v-if="text">{{text}}</p>
  </div>
</template>

<script>
/**
* @prop {String} size - Отвечает за размер лоадера и имеет следующие состояния:
    1.large
    2.medium
    3.small
* */
export default {
  name: "Loader",
  props: {
    text: {
      type: String,
    },
    center: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'medium'
    }
  }
}
</script>

<style scoped lang="scss">
.loader {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  > p {
    color: var(--colorTextSecondary);
  }
  &.center {
    justify-content: center;
    align-items: center;
  }
  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    &.large {
      width: 4rem;
      height: 4rem;
    }
    &.medium {
      width: 2rem;
      height: 2rem;
    }
    &.small {
      width: 1rem;
      height: 1rem;
    }

    vertical-align: text-bottom;
    border: 2px solid var(--colorComponentSpinnerBuffer);
    border-right-color: transparent;
    border-radius: 50%;
    animation: 0.85s linear infinite spinner-border;
    position: relative;
    > svg {
      position: absolute;
    }
    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
  }
}
</style>