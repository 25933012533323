import Vue from "vue";

export const profileModule ={
    state: ()=>({
        user: {},
        baseUrl:'/profile',
        responsibleList: {},
        optionsPerson: [],
        blocksResponsible:[]
    }),
    getters:{
        getUser: state => state.user,
        getBaseUrl: state => state.baseUrl,
        getResponsibleList: state => state.responsibleList,
        getOptionsPerson: state => state.optionsPerson,
        getBlocksResponsible: state => state.blocksResponsible,
    },
    mutations:{
        setUserAll: (state, resp) => {
            Vue.set(state, 'user', resp)
        },
        setUserObj: (state, obj) => {
            Vue.set(state, 'user', {...state.user, ...obj })
        },

        setBaseUrl: (state, resp) => {
            Vue.set(state, 'baseUrl', resp)
        },

        setResponsibleListAll: (state, resp) => {
            Vue.set(state, 'responsibleList', resp)
        },
        setResponsibleListObj: (state, obj) => {
            Vue.set(state, 'responsibleList', {...state.responsibleList, ...obj })
        },

        setOptionsPersonAll: (state, resp) => {
            Vue.set(state, 'optionsPerson', resp)
        },
        setBlocksResponsible: (state, resp) => {
            Vue.set(state, 'blocksResponsible', resp)
        },

    },
    actions:{
        SET_USER: ({ commit, dispatch, getters }, string) => {
            commit('SET_USER', string)
        },
    },
    namespaced:true
}