<template>
  <div class="card-body" v-if="!loadPage">
    <div class="editor-video-stories ">
      <h4 class="d-inline-block">{{$t('РедакторВидеоДляСторис')}}</h4>
      <div class="editor-video-stories__body">
        <div class="form-group flexible">
          <label for="video_stories">{{$t('Видео')}}</label>
          <a class="btn btn-info btn-sm" target="_blank" v-if="files.video_stories" :href="files.video_stories">{{$t('ПосмотретьЗагруженноеВидео')}}</a>
          <label for="video_stories" class="btn btn-info btn-sm">{{ $t('загрузитьФайл') }}</label>
          <input id="video_stories"  type="file" ref="video_stories" v-on:change="handleFileUploads('video_stories')" class="form-control hidden">
        </div>
        <div class="card-body" v-if="loadVidioStories">
          <div class="spinner-border text-platform" role="status">
            <span class="sr-only">{{ $t('загрузка') }}</span>
          </div>
          {{ $t('ИдётЗагрузкаДанных') }}
        </div>
        <div class="form-group flexible">
          <label for="audio_file">{{$t('Музыка')}}</label>
          <a class="btn btn-info btn-sm" target="_blank" v-if="files.audio_file" :href="files.audio_file">{{$t('ПосмотретьЗагруженнуюМузыку')}}</a>
          <label for="audio_file" class="btn btn-info btn-sm">{{ $t('загрузитьФайл') }}</label>
          <input id="audio_file"  type="file" ref="audio_file" v-on:change="handleFileUploads('audio_file')" class="form-control hidden">
        </div>
        <div class="card-body" v-if="loadAudioStories">
          <div class="spinner-border text-platform" role="status">
            <span class="sr-only">{{ $t('загрузка') }}</span>
          </div>
          {{ $t('ИдётЗагрузкаДанных') }}
        </div>
        <div class="editor-video-stories__body_picture">
          <div id="black-video-phone">

          </div>
          <div id="video-line">
            <svg id="video-line-svg">
              <line x1="0" y1="0" x2="0" y2="100%" stroke-width="10" :stroke="stylesVideoStories['B_1'].color"></line>
            </svg>
          </div>
          <div id="video-title" :style="`color:${stylesVideoStories['A_1'].color}`">{{$t('Объект')}} (А_1)</div>
          <div id="video-type" :style="`color:${stylesVideoStories['A_2'].color}`">{{$t('ТипОбъекта')}} (А_2)</div>
          <div id="video-body">
            <div id="video-body__el_1" :style="`border-color:${stylesVideoStories['B_2'].color}`">
              <div :style="`color:${stylesVideoStories['A_3'].color}`">{{$t('Заголовок')}} (А_3)</div>
              <div><!--до --><span :style="`color:${stylesVideoStories['A_4'].color}`">{{$t('описание')}} (А_4)</span> <!--рублей--></div>
            </div>
            <div id="video-body__el_2" :style="`border-color:${stylesVideoStories['B_2'].color}`">
              <div :style="`color:${stylesVideoStories['A_5'].color}`">{{$t('Заголовок')}} (А_5)</div>
              <div><span :style="`color:${stylesVideoStories['A_6'].color}`">{{$t('описание')}} (А_6)</span></div>
            </div>
            <div id="video-body__el_3" :style="`border-color:${stylesVideoStories['B_2'].color}`">
              <div :style="`color:${stylesVideoStories['A_7'].color}`">{{$t('Заголовок')}} (А_7)</div>
              <div><span :style="`color:${stylesVideoStories['A_8'].color}`">{{$t('часть_1')}} (А_8)</span></div>
              <div><span :style="`color:${stylesVideoStories['A_9'].color}`">{{$t('часть_2')}} (А_9)</span></div>
            </div>
            <div id="video-body__el_4" :style="`border-color:${stylesVideoStories['B_2'].color}`">
              <span :style="`color:${stylesVideoStories['A_10'].color}`">{{$t('Заголовок')}} (А_10)</span>
            </div>
          </div>
        </div>
        <div class="editor-video-stories__body_blocks">
          <div class="video-editor-block">
            <div class="video-editor-block__name">B_1 ({{$t('ЦветОсновнойЛинии')}})</div>
            <div class="video-editor-block__fields">
              <div class="video-editor-block__fields_el">
                <input class="form-control" type="color" :placeholder="$t('Цвет')" v-model="stylesVideoStories['B_1'].color"/>
              </div>
            </div>
          </div>
          <div class="video-editor-block">
            <div class="video-editor-block__name">B_2 ({{$t('ЦветЛинииБлоков')}})</div>
            <div class="video-editor-block__fields">
              <div class="video-editor-block__fields_el">
                <input class="form-control" type="color" :placeholder="$t('Цвет')" v-model="stylesVideoStories['B_2'].color"/>
              </div>
            </div>
          </div>
          <div class="video-editor-block">
            <div class="video-editor-block__name">A_1</div>
            <div class="video-editor-block__fields">
              <div class="video-editor-block__fields_el">
                <input class="form-control" type="color" :placeholder="$t('Цвет')" v-model="stylesVideoStories['A_1'].color"/>
              </div>
              <!--                    <div class="video-editor-block__fields_el">
                                    <input class="form-control" type="text" placeholder="Размер шрифта (px)"/>
                                  </div>-->
            </div>
          </div>
          <div class="video-editor-block">
            <div class="video-editor-block__name">A_2</div>
            <div class="video-editor-block__fields">
              <div class="video-editor-block__fields_el">
                <input class="form-control" type="color" :placeholder="$t('Цвет')" v-model="stylesVideoStories['A_2'].color"/>
              </div>
              <!--                    <div class="video-editor-block__fields_el">
                                    <input class="form-control" type="text" placeholder="Размер шрифта (px)"/>
                                  </div>-->
            </div>
          </div>
          <div class="video-editor-block">
            <div class="video-editor-block__name">A_3</div>
            <div class="video-editor-block__fields">
              <div class="video-editor-block__fields_el">
                <input class="form-control" type="color" :placeholder="$t('Цвет')" v-model="stylesVideoStories['A_3'].color"/>
              </div>
              <!--                    <div class="video-editor-block__fields_el">
                                    <input class="form-control" type="text" placeholder="Размер шрифта (px)"/>
                                  </div>-->
            </div>
          </div>
          <div class="video-editor-block">
            <div class="video-editor-block__name">A_4</div>
            <div class="video-editor-block__fields">
              <div class="video-editor-block__fields_el">
                <input class="form-control" type="color" :placeholder="$t('Цвет')" v-model="stylesVideoStories['A_4'].color"/>
              </div>
              <!--                    <div class="video-editor-block__fields_el">
                                    <input class="form-control" type="text" placeholder="Размер шрифта (px)"/>
                                  </div>-->
            </div>
          </div>
          <div class="video-editor-block">
            <div class="video-editor-block__name">A_5</div>
            <div class="video-editor-block__fields">
              <div class="video-editor-block__fields_el">
                <input class="form-control" type="color" :placeholder="$t('Цвет')" v-model="stylesVideoStories['A_5'].color"/>
              </div>
              <!--                    <div class="video-editor-block__fields_el">
                                    <input class="form-control" type="text" placeholder="Размер шрифта (px)"/>
                                  </div>-->
            </div>
          </div>
          <div class="video-editor-block">
            <div class="video-editor-block__name">A_6</div>
            <div class="video-editor-block__fields">
              <div class="video-editor-block__fields_el">
                <input class="form-control" type="color" :placeholder="$t('Цвет')" v-model="stylesVideoStories['A_6'].color"/>
              </div>
              <!--                    <div class="video-editor-block__fields_el">
                                    <input class="form-control" type="text" placeholder="Размер шрифта (px)"/>
                                  </div>-->
            </div>
          </div>
          <div class="video-editor-block">
            <div class="video-editor-block__name">A_7</div>
            <div class="video-editor-block__fields">
              <div class="video-editor-block__fields_el">
                <input class="form-control" type="color" :placeholder="$t('Цвет')" v-model="stylesVideoStories['A_7'].color"/>
              </div>
              <!--                    <div class="video-editor-block__fields_el">
                                    <input class="form-control" type="text" placeholder="Размер шрифта (px)"/>
                                  </div>-->
            </div>
          </div>
          <div class="video-editor-block">
            <div class="video-editor-block__name">A_8</div>
            <div class="video-editor-block__fields">
              <div class="video-editor-block__fields_el">
                <input class="form-control" type="color" :placeholder="$t('Цвет')" v-model="stylesVideoStories['A_8'].color"/>
              </div>
              <!--                    <div class="video-editor-block__fields_el">
                                    <input class="form-control" type="text" placeholder="Размер шрифта (px)"/>
                                  </div>-->
            </div>
          </div>
          <div class="video-editor-block">
            <div class="video-editor-block__name">A_9</div>
            <div class="video-editor-block__fields">
              <div class="video-editor-block__fields_el">
                <input class="form-control" type="color" :placeholder="$t('Цвет')" v-model="stylesVideoStories['A_9'].color"/>
              </div>
              <!--                    <div class="video-editor-block__fields_el">
                                    <input class="form-control" type="text" placeholder="Размер шрифта (px)"/>
                                  </div>-->
            </div>
          </div>
          <div class="video-editor-block">
            <div class="video-editor-block__name">A_10</div>
            <div class="video-editor-block__fields">
              <div class="video-editor-block__fields_el">
                <input class="form-control" type="color" :placeholder="$t('Цвет')" v-model="stylesVideoStories['A_10'].color"/>
              </div>
              <!--                    <div class="video-editor-block__fields_el">
                                    <input class="form-control" type="text" placeholder="Размер шрифта (px)"/>
                                  </div>-->
            </div>
          </div>
        </div>
        <div class="editor-video-stories__body_save" @click="saveVideoStories">{{ $t('сохранитьИзменения') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import axios from "axios";
import DefaultButton from "@/components/redesign/defaultButton.vue";

export default {
  name: "VideoEditorProject",
  components: {DefaultButton},
  data(){
    return{
      complex:{},
      files: {
        video_stories:'',
        audio_file:'',
      },
      loadPage:true,
      loadVidioStories:false,
      loadAudioStories:false,
      stylesVideoStories:{
        'B_1':{color:'#adff2f'},
        'B_2':{color:'#ffffff'},
        'A_1':{color:'#ffffff', size:'80px'},
        'A_2':{color:'#adff2f', size:'40px'},
        'A_3':{color:'#ffffff', size:'60px'},
        'A_4':{color:'#c7a285', size:'80px'},
        'A_5':{color:'#ffffff', size:'60px'},
        'A_6':{color:'#c7a285', size:'90px'},
        'A_7':{color:'#ffffff', size:'60px'},
        'A_8':{color:'#c7a285', size:'80px'},
        'A_9':{color:'#c7a285', size:'80px'},
        'A_10':{color:'#c7a285', size:'80px'},
      },
    }
  },
  methods:{
    ...mapMutations({
      setComplexAll: 'editorProjectData/setComplexAll',
    }),
    saveVideoStories(){

      var object = JSON.parse(JSON.stringify(this.complex))
      object.video_stories.url = this.files.video_stories
      object.video_stories.audio = this.files.audio_file
      object.video_stories.styles = this.stylesVideoStories
      this.setComplexAll(object)
      try {
        axios.post('/api/post-building?id='+this.$route.params.id, object).then((res)=>{
          this.$emit('successfulSave')
        })
      } catch (error) {
        this.$emit('unsuccessfulSave')
      }
    },
    handleFileUploads (input) {
      const formData = new FormData()
      var file = this.$refs[input].files[0]
      var self = this
      formData.append('file', file)
      axios.post('https://cdn.itrielt.ru/upload/file', formData).then(res => {
        if (res.data.status === 'success') {
          self.files[input]=res.data.url
          switch (input){
            case 'video_stories':
              self.loadVidioStories=false
              break;
            case 'audio_file':
              self.loadAudioStories=false
              break;
          }
        }
      })

    },
  },
  computed:{
    ...mapGetters({
      getComplex: 'editorProjectData/getComplex',
    }),
  },
  watch:{
    getComplex(newValue){
      this.complex=JSON.parse(JSON.stringify(newValue))
    }
  },
  mounted() {
    this.complex=JSON.parse(JSON.stringify(this.getComplex))

    if(!this.complex.video_stories)this.complex.video_stories={'url':null, 'audio':null}
    if(this.complex.video_stories.url) this.files.video_stories = JSON.parse(JSON.stringify(this.complex.video_stories.url))
    if(this.complex.video_stories.audio) this.files.audio_file = JSON.parse(JSON.stringify(this.complex.video_stories.audio))
    if(this.complex.video_stories.styles) this.stylesVideoStories = JSON.parse(JSON.stringify(this.complex.video_stories.styles))
    this.loadPage=false
  }
}
</script>

<style scoped lang="scss">

</style>