<template>
  <div
      class="icon-component"
      :class="{'clickability-icon':clickability}"
      @click="useFunction"
  >
    <div
        class="icon-component__hover"
        :style="stylesHoverBlock"
    ></div>
    <div
        class="icon-component__container"
        :style="stylesIcon"
    ></div>
  </div>
</template>

<script>
/**
 *
 * @prop {String} icon - содержит ссылку на иконку
 * @prop {String} color - содержит цвет иконки
 * @prop {Number} size - содержит размер иконки
 * @prop {Boolean} clickability - Отвечает кликабельность иконки:
 * 1. Если иконка кликабельна, то при наведении появляется ховер в виде круга и можно подписаться на функцию @click-element в родительском компоненте
 * 2. Если иконка не кликабельна, то ховер эффект отсутствует и нельзя подписаться на функцию (дефолтное состояние)
 */
export default {
  name: "iconComponent",
  props:{
    icon: {
      type: String,
      default: '/default-icons/zoom_in.svg'
    },
    color:{
      type: String,
      default: 'var(--colorIconPrimary)'
    },
    size:{
      type: Number,
      default: 16
    },
    clickability:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    stylesIcon() {
      return `
      mask: url('${this.icon}') no-repeat center / contain;
      -webkit-mask: url('${this.icon}') no-repeat center / contain;
      width: ${this.size}px;
      height:${this.size}px;
      background-color: ${this.color};
      `
    },
    sizeHoverBlock() {
      return Math.round(this.size*1.4375)
    },
    stylesHoverBlock() {
      return `
      width: ${this.sizeHoverBlock}px;
      height:${this.sizeHoverBlock}px;
      `
    },
  },
  methods:{
    useFunction(){
      if(this.clickability){
        this.$emit('click-element')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.icon-component {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &.clickability-icon:hover{
    cursor: pointer;
    .icon-component__hover{
      background-color: var(--colorBackgroundAlpha);
    }
  }
  .icon-component__hover{
    transition: all 0.3s ease;
    border-radius: 20px;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>