<template>
  <div class="card-body" v-if="!loadPage">
    <div class="form-group flexible">
      <label for="presentation">{{$t('ПрезентацияОбщаяФорматPdf')}}</label>
      <a class="btn btn-info btn-sm" target="_blank" v-if="files.presentation" :href="files.presentation">{{$t('ПосмотретьЗагруженнуюПрезентацию')}}</a>
      <label for="presentation" class="btn btn-info btn-sm">{{ $t('загрузитьФайл') }}</label>
      <div v-if="files.presentation"><default-button size="medium" appearance="negative" @click-element="deletePresentation('presentation')">{{ $t('удалить') }}</default-button></div>
      <input id="presentation"  type="file" ref="presentation" v-on:change="handleFileUploads('presentation')" class="form-control hidden">
    </div>
    <div class="card-body" v-if="loadPresentation">
      <div class="spinner-border text-platform" role="status">
        <span class="sr-only">{{ $t('загрузка') }}</span>
      </div>
      {{ $t('ИдётЗагрузкаДанных') }}
    </div>

    <div class="form-group flexible">
      <label for="calc_presentation">{{$t('ПрезентацияДляКалькулятораФорматPdf')}}</label>
      <a class="btn btn-info btn-sm" target="_blank" v-if="files.calc_presentation" :href="files.calc_presentation">{{$t('ПосмотретьЗагруженнуюПрезентацию')}}</a>
      <label for="calc_presentation" class="btn btn-info btn-sm">{{ $t('загрузитьФайл') }}</label>
      <div v-if="files.calc_presentation"><default-button size="medium" appearance="negative" @click-element="deletePresentation('calc_presentation')">{{ $t('удалить') }}</default-button></div>
      <input id="calc_presentation"  type="file" ref="calc_presentation" v-on:change="handleFileUploads('calc_presentation')" class="form-control hidden">
    </div>
    <div class="card-body" v-if="loadCalcPresentation">
      <div class="spinner-border text-platform" role="status">
        <span class="sr-only">{{ $t('загрузка') }}</span>
      </div>
      {{ $t('ИдётЗагрузкаДанных') }}
    </div>

    <div class="form-group flexible">
      <label for="developer_presentation">{{$t('ПрезентацияОЗастройщикеФорматPdf')}}</label>
      <a class="btn btn-info btn-sm" target="_blank" v-if="files.developer_presentation" :href="files.developer_presentation">{{$t('ПосмотретьЗагруженнуюПрезентацию')}}</a>
      <label for="developer_presentation" class="btn btn-info btn-sm">{{ $t('загрузитьФайл') }}</label>
      <div v-if="files.developer_presentation"><default-button size="medium" appearance="negative" @click-element="deletePresentation('developer_presentation')">{{ $t('удалить') }}</default-button></div>
      <input id="developer_presentation"  type="file" ref="developer_presentation" v-on:change="handleFileUploads('developer_presentation')" class="form-control hidden">
    </div>
    <div class="card-body" v-if="loadDeveloperPresentation">
      <div class="spinner-border text-platform" role="status">
        <span class="sr-only">{{ $t('загрузка') }}</span>
      </div>
      {{ $t('ИдётЗагрузкаДанных') }}
    </div>


    <div class="form-group palette-presentation">
      <div class="palette-presentation__header">
        <label for="developer_presentation">{{$t('ЦветНачальнойСтраницы')}}</label>
        <div><default-button size="medium" @click-element="setColorInitialPage">{{complex.presentation.backgroundColorInitialPage?$t('ВернутьИзначальныйЦвет'):$t('изменить')}}</default-button></div>
      </div>
      <label for="colorSystemSuccess900" class="el-palette" v-if="complex.presentation.backgroundColorInitialPage">
        <div class="el-palette__fill" :style="{backgroundColor:complex.presentation.backgroundColorInitialPage}"></div>
        <div class="el-palette__description">
          <div class="el-palette__description_value">{{complex.presentation.backgroundColorInitialPage}}</div>
        </div>
        <input class="el-palette__input" type="color" id="colorSystemSuccess900" v-model="complex.presentation.backgroundColorInitialPage"/>
      </label>
      <label for="colorSystemSuccess900" class="el-palette" v-else>
        <div class="el-palette__fill" :style="{backgroundColor:'var(--colorBackgroundAccent)'}"></div>
      </label>
    </div>

    <div class="btn btn-sm btn-success col-12" @click="savePresentation">{{ $t('сохранитьИзменения') }}</div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import axios from "axios";
import DefaultButton from "@/components/redesign/defaultButton.vue";

export default {
  name: "PresentationEditorProject",
  components: {DefaultButton},
  data(){
    return{
      complex:{},
      files: {
        presentation: '',
        calc_presentation:'',
        developer_presentation:'',
      },
      loadPage:true,
      loadPresentation:false,
      loadCalcPresentation:false,
      loadDeveloperPresentation:false,
    }
  },
  methods:{
    ...mapMutations({
      setComplexAll: 'editorProjectData/setComplexAll',
    }),
    savePresentation(){
      var object = JSON.parse(JSON.stringify(this.complex))
      object.presentation.presentation = this.files.presentation
      object.presentation.calc_presentation = this.files.calc_presentation
      object.presentation.developer_presentation = this.files.developer_presentation
      this.setComplexAll(object)
      try {
        axios.post('/api/post-building?id='+this.$route.params.id, object).then(()=>{
          this.$emit('successfulSave')
        })
      } catch (error) {
        this.$emit('unsuccessfulSave')
      }
    },
    deletePresentation(name){
      this.files[name]=''
    },
    setColorInitialPage(){
      if(this.complex.presentation?.backgroundColorInitialPage)this.complex.presentation.backgroundColorInitialPage=null
      else this.complex.presentation.backgroundColorInitialPage='#000000'
      this.$forceUpdate()
    },

    handleFileUploads (input) {
      const formData = new FormData()
      var file = this.$refs[input].files[0]
      var self = this
      formData.append('file', file)
      axios.post('https://cdn.itrielt.ru/upload/file', formData).then(res => {
        if (res.data.status === 'success') {
          self.files[input]=res.data.url
          switch (input){
            case 'presentation':
              self.loadPresentation=false
              break;
            case 'calc_presentation':
              self.loadCalcPresentation=false;
              break;
            case 'developer_presentation':
              self.loadDeveloperPresentation=false;
              break;
          }
        }
      })

    },
  },
  computed:{
    ...mapGetters({
      getComplex: 'editorProjectData/getComplex',
    }),
  },
  watch:{
    getComplex(newValue){
      this.complex=JSON.parse(JSON.stringify(newValue))
    }
  },
  mounted() {
    this.complex=JSON.parse(JSON.stringify(this.getComplex))

    if(!this.complex.presentation)this.complex.presentation={'presentation':null, 'calc_presentation':null, 'developer_presentation':null}
    if(this.complex.presentation.presentation) this.files.presentation = JSON.parse(JSON.stringify(this.complex.presentation.presentation))
    if(this.complex.presentation.calc_presentation) this.files.calc_presentation = JSON.parse(JSON.stringify(this.complex.presentation.calc_presentation))
    if(this.complex.presentation.developer_presentation) this.files.developer_presentation = JSON.parse(JSON.stringify(this.complex.presentation.developer_presentation))
    this.loadPage=false
  }
}
</script>

<style scoped lang="scss">

</style>