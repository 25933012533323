<script>

import DefaultNewInput from "@/components/redesign/defaultNewInput.vue";
import FormItem from "@/components/redesign/FormItem.vue";
import DefaultButton from "@/components/redesign/defaultButton.vue";

export default {
  name: "usefulLinksComplex",
  components: {DefaultButton, FormItem, DefaultNewInput},
  props: {
    usefulLinks: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    deleteUsefulLink (id) {
      const el = this.usefulLinksList.find(el => el.arr_id === id)
      if (el) {
        this.usefulLinks[el.arr_id].status = 'deleted'
      }
      else console.log('err')
    },
    saveUsefulLinks (id) {
      const el = this.usefulLinksList.find(el => el.arr_id === id)
      if (el) {
        if (!el.link||!el.name)alert(this.$i18n.t('ЗаполнитеОбязательныеПоля'))
        else {
          this.usefulLinks[el.arr_id].link = el.link
          this.usefulLinks[el.arr_id].name = el.name
          this.usefulLinks[el.arr_id].status = 'saved'
        }
      }
    },
    changeUsefulLinks (id) {
      const el = this.usefulLinksList.find(el => el.arr_id === id)
      if (el) this.usefulLinks[el.arr_id].status = 'change'
    },
    addUsefulLink () {
      this.usefulLinks.push({link: null, name:null, id: null, status: 'change'})
    }
  },
  computed: {
    usefulLinksList () {
        return this.usefulLinks.map((el,index) => {
          return {...el, arr_id: index}
        }).filter(el => el.status !== 'deleted')
    },
  }
}
</script>

<template>
  <div class="useful-links-wrapper">
    <div class="empty-fields" v-if="!usefulLinksList.length">{{$t('НетНиОднойСсылкиНажмитеНаПлюсЧтобыДобавить')}}</div>
    <div v-else class="useful-links-container">
      <div class="useful-links-container__el" v-for="(linker,id) in usefulLinksList" :key="linker.arr_id">
        <div @click="changeUsefulLinks(linker.arr_id)" v-if="linker.status === 'saved'" class="el-saved">
          <div>{{$t('Название')}}: {{linker.name}}</div>
          <div>{{$t('Ссылка')}}: {{linker.link}}</div>
        </div>
        <div class="el-change" v-else-if="linker.status === 'change'">
          <div class="el-change__header">
            <div class="el-change__header_title">{{$t('Ссылка')}} №{{id+1}}</div>
            <div class="el-change__header_functions">
              <div class="save-el" @click="saveUsefulLinks(linker.arr_id)">✔️</div>
              <div class="delete-el" @click="deleteUsefulLink(linker.arr_id)">❌</div>
            </div>
          </div>
          <div class="el-change__body">
            <form-item :title="$t('Название')" :required="true">
              <default-new-input
                  :placeholder="$t('ВведитеНазвание')"
                  v-model="usefulLinks[linker.arr_id].name"
              />
            </form-item>
            <form-item :title="$t('Ссылка')" :required="true">
              <default-new-input
                  :placeholder="$t('ВведитеСсылку')"
                  v-model="usefulLinks[linker.arr_id].link"
              />
            </form-item>
          </div>
        </div>
      </div>

    </div>
    <div class="useful-links-add">
      <default-button
          :left-icon="'/default-icons/zoom_in.svg'"
          size="medium"
          width="auto"
          type="secondary"
          @click-element="addUsefulLink"></default-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.useful-links-wrapper{
  display: flex;
  flex-direction: column;
  gap: 14px;
  .empty-fields{
    display: flex;
    justify-content: center;
    color:var(--colorTextPrimary);
  }
  .useful-links-container{
    display: flex;
    flex-direction: column;
    gap: 14px;
    .useful-links-container__el{
      .el-saved {
        padding: 14px;
        width: 100%;
        cursor: pointer;
        border-radius: 8px;
        background-color: var(--colorBackgroundSecondary);
        font-weight: 500;
        &:hover {
          background-color: var(--colorBackgroundSecondaryHover);
        }
      }
      .el-change{
        .el-change__header{
          display: flex;
          align-items: center;
          gap: 14px;
          .el-change__header_title{

          }
          .el-change__header_functions{
            display: flex;
            align-items: center;
            gap: 5px;
            .save-el, .delete-el{
              cursor: pointer;
            }
          }
        }
        .el-change__body{
          display: flex;
          align-items: flex-end;
          gap: 14px;
        }
      }
    }
  }
  .useful-links-add{
    display: flex;
    justify-content: flex-end;
  }
}
</style>