<script>
export default {
  name: "ambassadoriKa"
}
</script>

<template>
  <div class="ambassadori-ka">
    <div class="wrapper-privacy-header">
      <div class="privacy-header">
        კონფიდენციალურობის პოლიტიკა
      </div>
      <hr/>
    </div>
    <div class="privacy-description">
      <p>„ამბასადორი ბათუმი აილენდ“-ის ვებგვერდის ადმინისტრაცია ვალდებულია შეინარჩუნოს თქვენი კონფიდენციალურობა ინტერნეტში. ჩვენთვის მნიშვნელოვანია თქვენს მიერ მოწოდებული მონაცემების დაცვა. ჩვენი კონფიდენციალურობის პოლიტიკა ეფუძნება ევროკავშირის მონაცემთა დაცვის ზოგად რეგულაციას (GDPR). თქვენი პერსონალური მონაცემების შეგროვების მიზანი არის „ამბასადორი ბათუმი აილენდ“-ის პროექტების შესახებ ინფორმაციის მოწოდება, წინამდებარე ვებგვერდის ვიზიტორებთან კომუნიკაცია, მომხმარებლის მონაცემების დამუშავების შესაბამისად მომხმარებლის მიერ მოთხოვნილი ინფორმაციის წარდგენა და ქვემოთ ჩამოთვლილი სხვა ქმედებები.</p>
      <h1>პერსონალური მონაცემების შენახვა და დამუშავება</h1>
      <p>ჩვენ ვაგროვებთ და ვამუშავებთ თქვენს პერსონალურ მონაცემებს მხოლოდ თქვენი სურვილისამებრ. თქვენი ნებართვით ჩვენ შეგვიძლია შევაგროვოთ და დავამუშაოთ შემდეგი მონაცემები: სახელი და გვარი, ელექტრონული ფოსტის მისამართი, ტელეფონის ნომერი, თქვენი პერსონალური ინფორმაციის შეგროვება და დამუშავება ხდება ევროკავშირისა და საქართველოს კანონმდებლობის შესაბამისად.</p>
      <p>მომხმარებელი თანახმაა მიიღოს სარეკლამო შეტყობინებები  დან მის მიერ მითითებულ ელ.ფოსტის მისამართზე და/ან ტელეფონის ნომერზე;</p>
      <h1>მონაცემთა შენახვა, შეცვლა და წაშლა</h1>
      <p>მომხმარებელს, რომელმაც მიაწოდა ambassadoriisland.com თავისი პერსონალური მონაცემები, აქვს მათი შეცვლისა და წაშლის, ასევე ნებისმიერ დროს, ყოველგვარი განმარტების ან დასაბუთების გარეშე მონაცემთა დამუშავებაზე გაცემული თანხმობის გამოხმობის უფლება. დრო, რომლის განმავლობაშიც თქვენი პერსონალური მონაცემები შეინახება არის დრო, როდესაც გამოიყენება პერსონალური მონაცემები, რომელიც აუცილებელია ვებგვერდის ძირითადი საქმიანობისთვის. თქვენი პერსონალური მონაცემების შენახვას კომპანია განახორციელებს ამ ვებგვერდის ლეგიტიმური მიზნის მიღწევის ვადით და შემდგომ ვებგვერდის ადმინისტრაცია სამუდამოდ წაშლის მას. თქვენს პერსონალურ მონაცემებზე წვდომისთვის შეგიძლიათ დაუკავშირდეთ ადმინისტრაციას შემდეგ მისამართზე: marketing@islander.ge.</p>
      <h1>ვიზიტის ტექნიკური მონაცემების დამუშავება</h1>
      <p>თქვენი IP მისამართის, ambassadoriisland.com ვიზიტის დროის, ბრაუზერის პარამეტრების, ოპერაციული სისტემის და სხვა ტექნიკური ინფორმაციის ჩანაწერები ინახება მონაცემთა ბაზაში, როდესაც სტუმრობთ . ეს მონაცემები აუცილებელია ვებგვერდის შინაარსის სწორად ჩვენებისთვის. ამ მონაცემების გამოყენებით ვიზიტორის პიროვნების იდენტიფიცირება შეუძლებელია.</p>
      <h1>არასრულწლოვანის პირადი ინფორმაცია</h1>
      <p>თუ თქვენ ხართ არასრულწლოვანი ბავშვის მშობელი ან კანონიერი მეურვე და იცით, რომ ბავშვმა მოგვაწოდა მათი პირადი ინფორმაცია თქვენი თანხმობის გარეშე, გთხოვთ, დაგვიკავშირდეთ მისამართზე: marketing@islander.ge.აკრძალულია არასრულწლოვანი მომხმარებლების პერსონალური მონაცემების შეტანა მშობლების ან კანონიერი მეურვეების შეთანხმების გარეშე.</p>
      <h1>Cookies დამუშავება</h1>
      <p>ჩვენ ვიყენებთ Cookies-ფაილებს ვებგვერის შინაარსის სწორად ჩვენებისთვის და დათვალიერების მოხერხებულობისთვის, ეს არის პატარა ფაილები, რომლებიც ინახება თქვენს მოწყობილობაში. ისინი ეხმარებიან ვებგვერდს დაიმახსოვროს თქვენს შესახებ ინფორმაცია, მაგალითად, რომელ ენაზე იყენებთ ვებგვერდს და რომელი გვერდები გაქვთ უკვე გახსნილი. ეს ინფორმაცია სასარგებლო იქნება მომდევნო ვიზიტის დროს. Cookie ფაილების წყალობით, ვებგვერდის დათვალიერება ბევრად უფრო მოსახერხებელი ხდება. ამ ფაილების შესახებ მეტი შეგიძლიათ გაიგოთ აქ. თქვენ თავად შეგიძლიათ დააყენოთ Cookies მიღება და დაბლოკვა თქვენს ბრაუზერში. Cookie ფაილების მიღების შეუძლებლობამ შეიძლება შეზღუდოს ვებგვერდის ფუნქციონირება.</p>
      <h1>პერსონალური მონაცემების დამუშავება სხვა სერვისების მიერ</h1>
      <p>ეს ვებგვერდი იყენებს მესამე მხარის ონლაინ სერვისებს, რომლებიც ახორციელებენ მონაცემთა შეგროვებას ჩვენგან დამოუკიდებლად. ასეთი სერვისებია: Google Analytics, Facebook.com.</p>
      <p>ამ სერვისების მიერ შეგროვებული მონაცემები შეიძლება მიეწოდოს ამ ორგანიზაციების სხვა სერვისებს. მათ შეუძლიათ გამოიყენონ მონაცემები საკუთარი სარეკლამო ქსელის სარეკლამო პერსონალიზაციისთვის. თქვენ შეგიძლიათ გაეცნოთ ამ ორგანიზაციების მომხმარებელთა შეთანხმებებს მათ ვებგვერდზე. თქვენ ასევე შეგიძლიათ უარი თქვათ მათ მიერ თქვენი პერსონალური მონაცემების შეგროვებაზე. მაგალითად, Google Analytics Opt-out Browser Add-on შეგიძლიათ იხილოთ აქ. ჩვენ არ გადავცემთ პერსონალურ მონაცემებს სხვა ორგანიზაციებს ან სერვისებს, რომლებიც არ არის ჩამოთვლილი ამ კონფიდენციალურობის პოლიტიკაში. გამონაკლისის სახით, შეგროვებული მონაცემები შეიძლება მიეწოდოს სახელმწიფო ორგანოების კანონიერი მოთხოვნით, რომლებიც უფლებამოსილია მოითხოვონ ასეთი ინფორმაცია.</p>
      <h1>ბმულები სხვა ვებგვერდებზე</h1>
      <p>ჩვენი ვებგვერდი ambassadoriisland.com შეიძლება შეიცავდეს ბმულებს სხვა ვებგვერდებზე, რომლებიც არ არის ჩვენი კონტროლის ქვეშ. ჩვენ არ ვართ პასუხისმგებელი ამ ვებგვერდების შინაარსზე. ჩვენ გირჩევთ გაეცნოთ ყველა ვებგვერდის კონფიდენციალურობის პოლიტიკას, რომელსაც ეწვიეთ, თუ ასეთი პოლიტიკა არსებობს.</p>
      <h1>ცვლილებები კონფიდენციალურობის პოლიტიკაში</h1>
      <p>დროდადრო, ჩვენმა ვებგვერდმა ambassadoriisland.com შეიძლება განაახლოს ჩვენი კონფიდენციალურობის პოლიტიკა. ჩვენ ვაკვირდებით ნებისმიერ ცვლილებას კანონმდებლობაში, რომელიც ეხება პერსონალურ მონაცემებს ევროკავშირსა და საქართველოში. იმ შემთხვევაში, თუ თქვენ შეიტანეთ რომელიმე თქვენი პერსონალური მონაცემი ჩვენს ვებგვერდზე, თქვენ გეცნობებათ ჩვენს კონფიდენციალურობის პოლიტიკაში შეტანილი ცვლილებების შესახებ. თუ თქვენი პერსონალური მონაცემები და უფრო კონკრეტულად, თქვენი საკონტაქტო ინფორმაცია არასწორად იყო შეყვანილი, ჩვენ ვერ დაგიკავშირდებით.</p>
      <h1>გამოხმაურება და საბოლოო პუნქტები</h1>
      <p>შეგიძლიათ დაუკავშირდეთ „ამბასადორი ბათუმი აილენდ“-ის ადმინისტრაციასკონფიდენციალურობის პოლიტიკასთან დაკავშირებულ ნებისმიერ შეკითხვასთან დაკავშირებით: ნომერზე + 995 32 20 55 000 ან წინამდებარე ვებგვერდის შესაბამის განყოფილებაში მითითებული საკონტაქტო ფორმის შევსებით. თუ არ ეთანხმებით ამ კონფიდენციალურობის პოლიტიკას, თქვენ არ შეგიძლიათ გამოიყენოთ ambassadoriisland.com ის სერვისები. ამ შემთხვევაში თქვენ უნდა მოერიდოთ ჩვენს ვებგვერდს.</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.privacy-description {
  h1 {
    text-align: start;
    font-size: 18px;
    margin: 26px 0;
  }
  p {
    margin: 8px 0;
  }
}
</style>