<template>
  <div class="headline" :style="{position:positionHeader}" :class="{'two-lines':numberLines===2, 'grid-plain':grid==='plain', 'container-view-header':containerView}">
    <div class="headline__container">
      <div class="headline__container_first">
        <div class="left-headline" v-if="showGoBackButton">
          <default-button
              :left-icon="typeIconBack==='close'?'/default-icons/close.svg':'/default-icons/arrow_back.svg'"
              size="large"
              type="tertiary"
              @click-element="onGoBackButtonClick"
          ></default-button>
        </div>
        <div class="title-container" :style="!showGoBackButton?{paddingLeft:'16px'}:{}">
          <div v-if="title" class="title">{{ $i18n.t(title) }}</div>
          <div v-if="subtitle" class="subtitle">{{ $i18n.t(subtitle) }}</div>
        </div>
        <div class="functions-headline">
          <slot name="functions"></slot>
        </div>
        <div class="controls">
          <slot></slot>
        </div>
      </div>
      <div class="headline__container_second" v-if="numberLines!==1">
        <div class="functions-headline">
          <slot name="functions"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultButton from "@/components/redesign/defaultButton.vue";

/**
 * @prop {String} grid - содержит тип сетки хедера: 1) card 2) plain. plain имеет большие отступы.
 * @prop {String} title - Заголовок
 * @prop {String} subtitle - Подзаголовок
 * @prop {Boolean} showGoBackButton - Отвечает за отображение кнопки возврата
 * @prop {String} typeIconBack - Иконки вовзврата: 1. return - стрелка назад, 2. close - крестик
 * @prop {String} positionHeader - Отвечает за позиционирование компонента
 * @prop {Number} numberLines - Отвечает за количество линий. Шапка может быть из 1 или 2 линий. По дефолтку на мобилках 2 строки (0-743px), а на остальных устройствах 1 строка. Вторая строка нужна для "функций"
 * @prop {Boolean} containerView - Хедер изменяется в зависимости от размеров контейнера, а не страницы.
 */
export default {
  name: "defaultHeader",
  components: {DefaultButton},
  props: {
    grid:{
      type:String,
      default:'card'
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: null
    },
    showGoBackButton: {
      type: Boolean,
      default: true
    },
    typeIconBack:{
      type: String,
      default: 'return'
    },
    positionHeader:{
      type: String,
      default: 'relative'
    },
    numberLines:{
      type: Number,
      default: null
    },
    containerView: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    onGoBackButtonClick() {
      this.$emit('on-go-back-button-click')
    }
  }
}
</script>

<style scoped lang="scss">
@mixin mobileView(){
  .headline__container{
    padding: 0;
    .headline__container_first{
      padding: 0 8px;
      .title-container {
        .title {
          font-size: 17px;
          line-height: 22px;
        }
      }
      .functions-headline{
        display: none;
      }
    }
    .headline__container_second{
      display: block;
    }
  }
  &.grid-plain{
    .headline__container{
      .headline__container_first{
        padding: 0;
      }
    }
    &.two-lines{
      .headline__container{
        padding-bottom: 4px;
      }
    }
  }
}

.headline {
  background-color: var(--colorComponentHeaderBackground);
  border-bottom: 1px solid var(--colorStrokePrimary);
  z-index: 2000;
  position: relative;
  top:0;
  width: 100%;
  .headline__container{
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    .headline__container_first{
      padding: 0 4px;
      display: flex;
      align-items: center;
      .left-headline{
        padding: 0 4px;
        .icon-back {
          padding: 12px;
          cursor: pointer;
          .icon-back__container{
            min-height: 24px;
            max-height: 24px;
            min-width: 24px;
            max-width: 24px;
            svg{
              display: block;
              width: 100%;
              height: 100%;
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
      .title-container {
        padding: 12px 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        .title {
          color: var(--colorTextPrimary);
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }

        .subtitle {
          color: var(--colorTextSecondary);
          font-variant-numeric: lining-nums tabular-nums;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.035px;
        }
      }
      .controls {
        display: flex;
        align-items: center;
      }
    }
    .headline__container_second{
      display: none;
    }
  }
  &.container-view-header{
    container-type: inline-size;
  }
  &.two-lines{
    .headline__container{
      .headline__container_first{
        .functions-headline{
          display: none;
        }
      }
      .headline__container_second{
        display: block;
      }
    }
  }
  &.grid-plain{
    .headline__container{
      .headline__container_first{
        padding: 0 20px;
      }
    }
  }
}

@media screen and (max-width: 1919px){
  .headline {
    .headline__container{
      .headline__container_first{
        padding: 0;
      }
    }
    &.grid-plain{
      .headline__container{
        .headline__container_first{
          padding: 0 8px;
        }
      }
    }
  }
}
@media screen and (max-width: 743px){
  .headline {
    @include mobileView;
  }
}
@container  (max-width: 743px) {
  .headline{
    &.container-view-header{
      @include mobileView;
    }
  }
}
</style>