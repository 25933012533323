<template>
  <div class="card-body">
    <modal-container
        v-if="visibleModal"
        :title="$t('Пакет')"
        :confirmText="$t('сохранить')"
        :width="'700px'"
        @confirm-option="savePacket"
        @close-modal="closeModalEditor"
    >
      <div class="editor-modal-blocks-project">
        <div class="row">
          <div class="form-group">
            <label for="balcony">{{ $t('Литер') }}</label>
            <div class="container-dropdown-stock" v-if="litersSelect.length">
              <dropdown-component :elements="litersSelect" :open="'bottom'" :multiple="true" v-model="changeData.developments" :value="changeData.developments"></dropdown-component>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="floor_min">{{ $t('название') }}</label>
                <input type="text" class="form-control" id="floor_min" v-model="changeData.name">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="room">{{$t('НомерПомещения')}}</label>
                <input type="number" class="form-control" id="room" v-model="changeData.room">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="square_min">{{$t('ПлощадьОтМ2')}}</label>
                <input type="number" class="form-control" id="square_min" v-model="changeData.square_min">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="square_max">{{ $t('ПлощадьДоМ2') }}</label>
                <input type="number" class="form-control" id="square_max" v-model="changeData.square_max">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="sum_square">{{$t('Стоимость')}}, {{$store.getters.getCurrency.unit}}/{{$t('м²')}}</label>
                <input type="number" class="form-control" id="sum_square" disabled readonly v-if="changeData.percent_square || changeData.price || changeData.percent_all" v-model="changeData.sum_square">
                <input type="number" class="form-control" id="sum_square" v-else v-model="changeData.sum_square">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="percent_square">{{$t('Стоимость')}}, %/{{$t('м²')}}</label>
                <input type="number" class="form-control" id="percent_square" disabled readonly v-if="changeData.sum_square || changeData.price || changeData.percent_all" v-model="changeData.percent_square">
                <input type="number" class="form-control" id="percent_square" v-else v-model="changeData.percent_square">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="sum_all">{{$t('Стоимость')}}, {{$store.getters.getCurrency.unit}}</label>
                <input type="number" class="form-control" id="price" disabled readonly v-if="changeData.sum_square || changeData.percent_square || changeData.percent_all" v-model="changeData.price">
                <input type="number" class="form-control" id="price" v-else v-model="changeData.price">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="percent_all">{{$t('Стоимость')}}, %</label>
                <input type="number" class="form-control" id="percent_all" disabled readonly v-if="changeData.sum_square || changeData.price || changeData.percent_square" v-model="changeData.percent_all">
                <input type="number" class="form-control" id="percent_all" v-else v-model="changeData.percent_all">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="sum_all">{{$t('КоличествоКомнат')}}</label>
                <input type="number" class="form-control" id="price" v-model="changeData.room_count">
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="img-admin-repair">
              <label for="fileс">{{ $t('Изображение') }}</label>
              <label for="fileс" class="btn btn-info btn-sm" v-if="!changeData.picture[0]">{{ $t('загрузитьИзображение') }}</label>
            </div>
            <input id="fileс"  type="file" ref="fileс" v-on:change="handlePacketFileUploads()" class="form-control hidden">
            <div class="files" v-if="changeData.picture[0]">
              <div class="file">
                <div class="delete" @click="deletePacketFile">❌</div>
                <img :src="changeData.picture[0]" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal-container>
    <div class="card-body">
      <div class="container p-30" id="wrapper-row-admin">
        <div class="row">
          <div class="col-md-12 main-datatable">
            <button class="btn btn-sm btn-success d-inline-block mb-2 col-12" @click="createPacket()">{{ $t('Добавить') }}</button>
            <div class="card_body">
              <div class="overflow-x">
                <table id="filtertable4" class="table cust-datatable dataTable no-footer">
                  <thead>
                  <tr>
                    <th>{{ $t('Литер') }}</th>
                    <th>{{ $t('название') }}</th>
                    <th>{{ $t('Стоимость') }}</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>

                  <tr v-for="packet in packets" :key="packet.id">
                    <td><slot v-if="packet.developments">{{getComplex(packet.developments)}}</slot></td>
                    <td>{{packet.name}}</td>
                    <td>{{packet.price}}</td>
                    <td><button  @click="showModal(packet)" class="btn btn-sm btn-warning">{{$t('редактировать')}}</button><button class="btn btn-sm btn-danger m-l-10" @click="deletePacket(packet.id)">{{ $t('удалить') }}</button></td> <!--tag="button" :to="'/packet/'+packet.id"-->
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div></div>
  </div>
</template>

<script>
import 'datatables.net-bs5'
import 'datatables.net-fixedheader-bs5'
import 'datatables.net-responsive-bs5'
import 'datatables.net-select-bs5'
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css'
import $ from 'jquery'
import {mapGetters, mapMutations} from "vuex";
import priceFormat from "@/mixins/priceFormat";
import axios from "axios";
import dropdownComponent from "@/components/dropdown.vue";
import ModalContainer from "@/components/redesign/modalContainer.vue";

export default {
  name: "AdditionalPackagesEditorProject",
  components: {ModalContainer, dropdownComponent},
  mixins: [priceFormat],
  data(){
    return{
      complex:{},
      table: null,
      changeData: {},
      visibleModal:false,
      packets: [],
    }
  },
  methods:{
    loadPackets() {
      if(this.table !== null) this.table.destroy()
      axios.get('/api/get-packets?id='+this.$route.params.id).then(response => {
        this.packets = response.data
        setTimeout(() => {
          $.fn.dataTable.ext.errMode = 'none';
          this.table = $('#filtertable4').DataTable({
            stateSave: true,
            destroy: true,
            paging: true,
            pageLength: 25,
            language: {
              'decimal': '',
              'emptyTable': `${this.$i18n.t('ДанныхНет')}`,
              'info': `${this.$i18n.t('ПоказаноС')} _START_ ${this.$i18n.t('по')} _END_ ${this.$i18n.t('из')} _TOTAL_ ${this.$i18n.t('строк')}`,
              'infoEmpty': '',
              'infoFiltered': `(${this.$i18n.t('найденоИзИзначальных')} _MAX_ строк)`,
              'infoPostFix': '',
              'thousands': ',',
              'lengthMenu': `${this.$i18n.t('ПоказатьПо')} _MENU_`,
              'loadingRecords': `${this.$i18n.t('Загрузка')}...`,
              'processing': `${this.$i18n.t('Поиск')}...`,
              'search': `${this.$i18n.t('Поиск')}:`,
              'zeroRecords': `${this.$i18n.t('СовпаденийНеНайдено')}`,
              'paginate': {
                'first': `${this.$i18n.t('Первая')}`,
                'last': `${this.$i18n.t('Последняя')}`,
                'next': `${this.$i18n.t('Вперед')}`,
                'previous': `${this.$i18n.t('Назад')}`
              },
              'aria': {
                'sortAscending': `: ${this.$i18n.t('сортировкаПоВозрастанию')}`,
                'sortDescending': `: ${this.$i18n.t('сортировкаПоУбыванию')}`
              }
            }
          })
        }, 100)
      })
    },
    savePacket() {
      var object = JSON.parse(JSON.stringify(this.changeData))
      if(object.picture.length===0)object.picture=null
      axios.post('/api/post-packet?id='+this.changeData.id, object).then(()=>{
        this.changeData = {}
      })
    },
    createPacket() {
      axios.post('/api/create-packet?id='+this.$route.params.id).then((res)=>{
        window.open('/packet/' + res.data.id, "_self")
      })
    },
    deletePacket(id) {
      axios.post('/api/delete-packet?id='+id).then(()=>{
        this.loadPackets()
      })
    },
    deletePacketFile () {
      this.changeData.picture=[]
    },
    handlePacketFileUploads () {
      var input = 'fileс'
      var file = this.$refs[input].files[0]
      var self = this
      var reader = new FileReader()
      reader.fileName = file.name
      reader.onload = function (e) {
        $.post('https://cdn.itrielt.ru/upload/file', {
          file: {
            name: e.target.fileName,
            data: e.target.result
          }
        }).then(data => {
          if (data.status === 'success') {
            self.changeData.picture.push(data.url)
            self.$forceUpdate()
          }
        })
      }
      reader.readAsDataURL(file)
    },
    getComplex (developments) {
      var array =[]
      developments.forEach(val => {
        if(val && this.liters[val]) {
          array.push(this.liters[val])
        }
      })
      return array.join(', ')
    },

    closeModalEditor(){
      this.visibleModal=false
      this.changeData = {}
    },
    showModal (data) {
      if(data.id){
        this.changeData = data
        this.visibleModal = true
      }
    },
  },
  computed:{
    ...mapGetters({
      storeComplex: 'editorProjectData/getComplex',
      liters: 'editorProjectData/getLiters',
      litersSelect: 'editorProjectData/getLitersSelect',
    }),
  },
  watch:{
    storeComplex(newValue){
      this.complex=JSON.parse(JSON.stringify(newValue))
    }
  },
  mounted() {
    this.complex=JSON.parse(JSON.stringify(this.storeComplex))
    this.loadPackets()
  }
}
</script>

<style scoped lang="scss">

</style>