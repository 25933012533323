<template>
  <div class="card-body">
    <div class="container p-30" id="wrapper-row-admin">
      <div class="row">
        <div class="col-md-12 main-datatable">
          <button class="btn btn-sm btn-success d-inline-block mb-2 col-12" @click="createOrg()">{{$t('ДобавитьОбъект')}}</button>
          <div class="card_body">
            <div class="overflow-x">
              <table id="filtertable3" class="table cust-datatable dataTable no-footer">
                <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t('ЖК') }}</th>
                  <th>{{ $t('ДомЛитер') }}</th>
                  <th>{{ $t('сортировка') }}</th>
                  <th>{{ $t('Адрес') }}</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>

                <tr v-for="build in complex.buildings" :key="build.id">
                  <td>{{ build.external_id }}</td>
                  <td>{{ build.name }}</td>
                  <td>{{ build.house }}</td>
                  <td>{{ build.sort }}</td>
                  <td>{{ build.address }}</td>
                  <td><router-link tag="button" :to="'/building-structure/'+complex.id+'/'+build.id" class="btn btn-sm btn-warning">{{$t('редактировать')}}</router-link></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'datatables.net-bs5'
import 'datatables.net-fixedheader-bs5'
import 'datatables.net-responsive-bs5'
import 'datatables.net-select-bs5'
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css'
import $ from 'jquery'
import {mapGetters, mapMutations} from "vuex";
import axios from "axios";

export default {
  name: "StructureEditorProject",
  components: {},
  data(){
    return{
      complex:{},
    }
  },
  methods:{
    createOrg() {
      axios.post('/api/create-building?id='+this.$route.params.id).then(()=>{
        this.loadComplex()
      })
    },
  },
  computed:{
    ...mapGetters({
      getComplex: 'editorProjectData/getComplex',
    }),
  },
  watch:{
    getComplex(newValue){
      this.complex=JSON.parse(JSON.stringify(newValue))
    }
  },
  mounted() {
    this.complex=JSON.parse(JSON.stringify(this.getComplex))

    setTimeout(() => {
      $.fn.dataTable.ext.errMode = 'none';
      $('#filtertable3').DataTable({
        stateSave: true,
        destroy: true,
        paging: true,
        pageLength: 25,
        language: {
          'decimal': '',
          'emptyTable': `${this.$i18n.t('ДанныхНет')}`,
          'info': `${this.$i18n.t('ПоказаноС')} _START_ ${this.$i18n.t('по')} _END_ ${this.$i18n.t('из')} _TOTAL_ ${this.$i18n.t('строк')}`,
          'infoEmpty': '',
          'infoFiltered': `(${this.$i18n.t('найденоИзИзначальных')} _MAX_ строк)`,
          'infoPostFix': '',
          'thousands': ',',
          'lengthMenu': `${this.$i18n.t('ПоказатьПо')} _MENU_`,
          'loadingRecords': `${this.$i18n.t('Загрузка')}...`,
          'processing': `${this.$i18n.t('Поиск')}...`,
          'search': `${this.$i18n.t('Поиск')}:`,
          'zeroRecords': `${this.$i18n.t('СовпаденийНеНайдено')}`,
          'paginate': {
            'first': `${this.$i18n.t('Первая')}`,
            'last': `${this.$i18n.t('Последняя')}`,
            'next': `${this.$i18n.t('Вперед')}`,
            'previous': `${this.$i18n.t('Назад')}`
          },
          'aria': {
            'sortAscending': `: ${this.$i18n.t('сортировкаПоВозрастанию')}`,
            'sortDescending': `: ${this.$i18n.t('сортировкаПоУбыванию')}`
          }
        }
      })
    }, 100)

  }
}
</script>

<style scoped lang="scss">

</style>